import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"

const HomeFoto = () => {
  return (
    <StaticQuery
      query={graphql`
        query HomeFoto {
          datoCmsPaginaDeInicio {
            fotoPrincipal {
              url
            }
          }
        }
      `}
      render={data => (
        <motion.div
          initial={{ opacity: 0, y: -80 }}
          animate={{ opacity: 1, y: 0, duration: 1.5 }}
          className="w-full bg-gray-100 mt-8 home-foto"
          style={{
            backgroundImage: `url(
              ${data.datoCmsPaginaDeInicio.fotoPrincipal.url}
            )`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
          }}
        ></motion.div>
      )}
    />
  )
}

export default HomeFoto
