import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Navigation2 from "../components/navigation2"
import HomeFoto from "../components/home-img"
import HomeFirma from "../components/home-firma"
import HomeSocios from "../components/home-socios"
import HomeContacto from "../components/home-contacto"

const IndexPage = ({ data }) => (
  <Layout pageTitle="Consultores en Derecho Público">
    <div className="w-full lg:w-10/12 mx-auto">
      <Navigation2 />

      <HomeFoto />

      <HomeFirma />

      <HomeSocios />
    </div>

    <HomeContacto />
  </Layout>
)

export default IndexPage
