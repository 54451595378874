import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Chambers from "../images/chambers.png"

const HomeFirma = () => {
  return (
    <StaticQuery
      query={graphql`
        query HomeFirma {
          datoCmsPaginaDeInicio {
            titulo
            subtitulo
            texto
            sello1 {
              fluid {
                src
              }
            }
            sello2 {
              fluid {
                src
              }
            }
          }
        }
      `}
      render={data => (
        <div className="w-10/12 lg:w-full lg:flex lg:flex-row-reverse mx-auto mt-5 lg:mt-12 mb-8">
          <div className="w-full lg:w-1/4 text-center">
            <img
              src={data.datoCmsPaginaDeInicio.sello1.fluid.src}
              alt="Top Rated in Latin America"
              className="mx-auto mb-5"
            />

            <img
              src={data.datoCmsPaginaDeInicio.sello2.fluid.src}
              alt="Top Rated in Latin America"
              className="mx-auto mb-5 mt-3"
              width="190"
            />
          </div>
          <div className="w-full lg:w-3/4 pageTexts">
            <h5 className="uppercase text-xs text-gray-600">
              {data.datoCmsPaginaDeInicio.subtitulo}
            </h5>
            <h1 className="text-3xl">{data.datoCmsPaginaDeInicio.titulo}</h1>

            <div
              className="home-body"
              dangerouslySetInnerHTML={{
                __html: data.datoCmsPaginaDeInicio.texto,
              }}
            ></div>

            <Link
              to="nuestra-firma"
              className="inline-block mt-8 py-3 px-5 bg-gray-200 text-sm hover:bg-gray-900 hover:text-gray-100 montserrat transition duration-300 ease-in "
            >
              Conozca La Firma
            </Link>
          </div>
        </div>
      )}
    />
  )
}

export default HomeFirma
